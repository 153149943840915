import * as ActionTypes from '../constants/actionTypes';

const initialState = {
	user: null,
	error: null,
	loading: null,
};

export default function access(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.LOGIN_REQUESTED:
		case ActionTypes.REGISTER_REQUESTED:
			return {
				...state,
				loading: 'login',
			};
		case ActionTypes.PROFILE_REQUESTED:
			return {
				...state,
				loading: 'profile',
			};
		case ActionTypes.PROFILE_UPDATED: {
			return {
				...state,
				user: {
					...state.user,
					isAuthenticated: true,
					loggedUserObj: {
						...state.user.loggedUserObj,
						...action.user,
					},
				},
				error: null,
				loading: null,
			};
		}
		case ActionTypes.LOGIN_SUCCEEDED:
		case ActionTypes.REGISTER_SUCCEEDED:
		case ActionTypes.PROFILE_SUCCEEDED: {
			return {
				...state,
				user: {
					...state.user,
					isAuthenticated: true,
					loggedUserObj: action.user,
				},
				error: null,
				loading: null,
			};
		}
		case ActionTypes.LOGIN_FAILED:
		case ActionTypes.REGISTER_FAILED: {
			return {
				...state,
				error: action.error,
				loading: null,
			};
		}
		case ActionTypes.PROFILE_FAILED:
		case ActionTypes.LOGOUT_SUCCEEDED: {
			return {
				...state,
				user: {
					isAuthenticated: false,
				},
				error: null,
				loading: null,
			};
		}
		default:
			return state;
	}
}
