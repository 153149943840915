// Modal Alert
export const TOGGLE_MODAL_REQUESTED = 'TOGGLE_MODAL_REQUESTED';
export const TOGGLE_MODAL_SUCCEEDED = 'TOGGLE_MODAL_SUCCEEDED';

// Login
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

// Register
export const REGISTER_REQUESTED = 'REGISTER_REQUESTED';
export const REGISTER_SUCCEEDED = 'REGISTER_SUCCEEDED';
export const REGISTER_FAILED = 'REGISTER_FAILED';

// Logout
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

// Profile
export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PROFILE_SUCCEEDED = 'PROFILE_SUCCEEDED';
export const PROFILE_FAILED = 'PROFILE_FAILED';

// Products
export const PRODUCT_REQUESTED = 'PRODUCT_REQUESTED';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const PRODUCT_SUCCEEDED = 'PRODUCT_SUCCEEDED';
export const PRODUCT_FAILED = 'PRODUCT_FAILED';
export const PRODUCT_SETTINGS_UPDATED = 'PRODUCT_SETTINGS_UPDATED';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';
export const PRODUCT_SET_TEMP = 'PRODUCT_SET_TEMP';
