import * as ActionTypes from '../constants/actionTypes';
import _ from 'lodash';

const initialState = {
	error: null,
	loading: null,
};

export default function product(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PRODUCT_REQUESTED:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.PRODUCT_SETTINGS_UPDATED: {
			if (state[action.key]) {
				let clone = _.cloneDeep(state[action.key]);
				clone.settings = _.assignIn(clone.settings || {}, action.settings);
				return {
					...state,
					[action.key]: clone,
				};
			}
			break;
		}

		case ActionTypes.PRODUCT_SELECTED: {
			return {
				...state,
				last: action.product_type,
			};
		}

		case ActionTypes.PRODUCT_SET_TEMP: {
			let product = state[action.product_type];
			if (product) {
				return {
					...state,
					[action.product_type]: { ...product, ...action.data },
				};
			} else break;
		}

		case ActionTypes.PRODUCT_UPDATED:
		case ActionTypes.PRODUCT_SUCCEEDED: {
			if (action.product)
				return {
					...state,
					[action.product.product_type]: action.product,
					error: null,
					loading: null,
				};
			break;
		}
		case ActionTypes.PRODUCT_FAILED: {
			return {
				...state,
				error: action.error,
				loading: null,
			};
		}
		case ActionTypes.LOGOUT_SUCCEEDED: {
			return {
				error: null,
				loading: null,
			};
		}
		default:
			break;
	}
	return state;
}
